<template>
  <div class="importExcel">
    <div class="row-me">
      <div class="title1">模板下载：</div>
      <div class="column-me flex1">
        <div class="row-me row-center grey-div">
          <div class="name flex1">{{ name }}</div>
          <div class="btn-blue w100 btn-height40 mR20" @click="clickDownload">下载</div>
        </div>
        <div class="column-me mT10">
          <div class="title2">注意事项：</div>
          <div class="title2">请先下载我们提供给您的模板，修改填入数据后再导入文件。</div>
          <div class="title2">点击导入按钮时，请注意模板的文件名是否与导入项一致。</div>
          <div class="title2">
            为保证数据正常导入，导入过程中请不要进行其他操作，否则系统不能正确匹配相关数据，从而导致数据导入失败。
          </div>
        </div>
      </div>
    </div>
    <div class="row-me row-center mT30">
      <div class="title1">导入文件：</div>
      <div class="row-me row-center flex1">
        <div class="file-div">{{ fileName }}</div>
        <el-upload ref="upload" action="" accept=".xls,.xlsx" :show-file-list="false" :auto-upload="false"
                   :http-request="uploadFile" :on-change="fileChange">
          <template #trigger>
            <div class="btn-grey w110 btn-height40 margin-left20">选择文件</div>
          </template>
          <!-- 不加默认上传按钮会报错 -->
          <div class="btn-blue w110 btn-height40" @click="clickOk" v-show="false">确定</div>
        </el-upload>
      </div>
    </div>
    <div class="w100Per row-me row-center flex-end1 mT100">
      <div class="btn-blank w100 btn-height40 mR20" @click="clickCancel">取消</div>
      <div class="btn-blue w100 btn-height40" @click="clickOk">导入</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: { //模板名称
      type: String,
      default: '导入模板[Excel]'
    },
    downloadUrl: { //模板下载链接
      type: String,
      default: ''
    },
    requestUrl: { //上传请求地址
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileName: '',
    }
  },
  // html加载完成之前
  created() {

  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    //下载
    clickDownload() {
      if (this.$method.isEmpty(this.downloadUrl)) {
        this.$message.error("模板未配置下载链接")
        return
      }
      window.open(this.downloadUrl)
    },
    //确定
    clickOk() {
      if (this.fileName === '' || this.fileName === null) {
        this.$message.error('请先选择文件')
        return
      }
      this.$refs.upload.submit()
    },
    //取消
    clickCancel() {
      this.$emit('importSuccess', 'cancel')
    },
    //文件状态改变
    fileChange(file, fileList) {
      this.fileName = file.name
    },
    //上传
    uploadFile(param) {
      let file = param.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$uploadFile(this.requestUrl, formData).then((res) => {
        if (res) {
          this.$emit('importSuccess', 'refresh')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
}
</script>

<style lang="scss" scoped>
.importExcel {
  font-size: 18px;
  color: #252630;
  margin: 10px;

  .title1 {
    width: 120px;
  }

  .grey-div {
    width: 100%;
    height: 60px;
    background-color: #F5F5F5;

    .img-excel {
      width: 50px;
      height: 60px;
      object-fit: scale-down;
      margin-left: 20px;
    }

    .name {
      font-size: 18px;
      margin-left: 20px;
    }
  }

  .title2 {
    font-size: 14px;
    color: #FF2929;
    line-height: 25px;
  }

  .file-div {
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    border: 1px solid #D6DAE2;
  }
}
</style>
