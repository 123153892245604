<template>
  <div class="userManage">
    <div class="page-title">用户管理列表</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>模糊搜索</div>
      <div class="w300 mL10">
        <el-input placeholder="请输入用户ID、昵称、手机号" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">设备搜索</div>
      <div class="w200 mL10">
        <el-input placeholder="请输入设备名称或编码" style="width: 100%;" clearable v-model="code"></el-input>
      </div>
      <div class="mL20">注册时间</div>
      <div class="w400 mL10">
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        end-placeholder="结束时间" style="width: 100%;" clearable v-model="date">
        </el-date-picker>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w150 btn-height40" @click="clickBatchImport">批量导入健康数据</div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickBatchExport">批量导出</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickBatchDelete">批量删除</div>
    </div>
    <div class="column-me flex1 overflow-auto mT20">
      <div class="flex1 overflow-auto">
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" @selection-change="handleSelectionChange" height="100%">
          <el-table-column type="selection" width="60" align="center"/>
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="id" label="ID" align="center" show-overflow-tooltip width="120"></el-table-column>
          <el-table-column label="头像" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <el-image :src="scope.row.portrait" :preview-src-list="[scope.row.portrait]" fit="cover"
                        class="img50 radius50P" hide-on-click-modal>
                <template #error>
                  <img src="../../assets/img/header.png" class="img50 radius50P objFitCover">
                </template>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="昵称" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column prop="username" label="真实姓名" align="center" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column prop="workNo" label="工号" align="center" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column prop="phone" label="手机号" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column prop="watchDevice.name" label="绑定的设备名称" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column prop="watchDevice.code" label="设备编码" align="center" show-overflow-tooltip
                           width="180"></el-table-column>
          <el-table-column prop="deptName" label="所属组织" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column label="出行次数" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div class="blue-text" @click="clickTravel(scope.row)">{{ scope.row.watchTravel.length }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="watchDeviceRecord.createdTime" label="最近一次健康数据" align="center"
                           show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column label="健康状态" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div v-if="scope.row.watchDeviceRecord&&scope.row.watchDeviceRecord.isException===1">异常</div>
              <div v-if="scope.row.watchDeviceRecord&&scope.row.watchDeviceRecord.isException===0">正常</div>
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="注册时间" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column label="状态" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div v-if="scope.row.status===0">正常</div>
              <div v-if="scope.row.status===1">已冻结</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="220" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <el-upload action="" :show-file-list="false" :before-upload="beforeAvatarUpload"
                           accept=".pdf" :auto-upload="true" :http-request="e=>uploadFile(e,scope.row)">
                  <div class="blue-text">上传体检报告</div>
                </el-upload>
                <div class="blue-text mL10" @click="clickDetail(scope.row)">详情</div>
                <el-popconfirm title="您确认冻结吗？" width="250" @confirm="clickFreeze(scope.row,1)"
                               v-if="scope.row.status===0">
                  <template #reference>
                    <div class="blue-text mL10">冻结</div>
                  </template>
                </el-popconfirm>
                <el-popconfirm title="您确认解冻吗？" width="250" @confirm="clickFreeze(scope.row,0)"
                               v-if="scope.row.status===1">
                  <template #reference>
                    <div class="blue-text mL10">解冻</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="批量导入健康数据" v-model="visibleImport" width="1000px" top="10vh" :destroy-on-close="true">
      <importExcel name="健康数据信息导入模板" :download-url="downloadUrl" request-url="/users/userImport"
                   @importSuccess="importSuccess"></importExcel>
    </el-dialog>

    <el-dialog title="用户详情" v-model="visibleUser" width="1000px" top="5vh" :destroy-on-close="true">
      <div class="pLR20">
        <div class="font18 font-bold">基础信息</div>
        <div class="row-me row-center mT20">
          <div class="flex1">昵称：{{ detailItem.nickname }}</div>
          <div class="flex1">真实姓名：{{ detailItem.username }}</div>
          <div class="flex1">手机号：{{ detailItem.phone }}</div>
        </div>
        <div class="row-me row-center mT20">
          <div class="flex1">所绑设备名称：{{ detailItem.watchDevice ? detailItem.watchDevice.name : '' }}</div>
          <div class="flex1">设备编码：{{ detailItem.watchDevice ? detailItem.watchDevice.code : '' }}</div>
          <div class="flex1">出行次数：{{ detailItem.watchDevice ? detailItem.watchTravel.length : '' }}</div>
        </div>
        <div class="row-me row-center mT20">
          <div class="flex2">
            最近一次更新健康数据：{{ detailItem.watchDeviceRecord ? detailItem.watchDeviceRecord.createdTime : '' }}
          </div>
          <div class="flex1">
            健康状态：{{
              detailItem.watchDeviceRecord ? (detailItem.watchDeviceRecord.isException === 1 ? '异常' : '正常') : ''
            }}
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="flex1">
            最新心率值：{{ detailItem.watchDeviceRecord ? detailItem.watchDeviceRecord.heartRate : '' }}
          </div>
          <div class="flex1">
            最新血压值：{{ detailItem.watchDeviceRecord ? detailItem.watchDeviceRecord.bloodPressure : '' }}
          </div>
          <div class="flex1">
            最新血氧值：{{ detailItem.watchDeviceRecord ? detailItem.watchDeviceRecord.bloodOxygen : '' }}
          </div>
        </div>
        <div class="font18 font-bold mT30">体检报告信息</div>
        <div class="column-me mT20">
          <div>
            <el-table :data="detailItem.watchMedicals" border header-cell-class-name="table-header-style"
                      cell-class-name="table-cell-style">
              <el-table-column type="index" label="序号" width="80" align="center"/>
              <el-table-column prop="name" label="体检报告名称" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="createdTime" label="上传时间" align="center"
                               show-overflow-tooltip></el-table-column>
              <el-table-column label="操作" align="center" show-overflow-tooltip width="180">
                <template #default="scope">
                  <div class="row-me center-all">
                    <div class="blue-text" @click="clickPreview(scope.row)">预览</div>
                    <div class="blue-text mL10" @click="clickDownload(scope.row)">下载</div>
                    <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                      <template #reference>
                        <div class="red-text mL10">删除</div>
                      </template>
                    </el-popconfirm>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mT10">
            <el-pagination @current-change="handleCurrentChange1" page-size="5" :current-page.sync="pageNo1"
                           layout="total, prev, pager, next, jumper" :total="total1">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="行程信息列表" v-model="visibleTravel" width="1500px" top="10vh" :destroy-on-close="true">
      <div class="pLR20">
        <div class="row-me row-center">
          <div>选择行程时间：</div>
          <div class="w400">
            <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            end-placeholder="结束时间" style="width: 100%;" clearable v-model="selectDate">
            </el-date-picker>
          </div>
          <div class="btn-blue w100 btn-height40 mL20" @click="clickSearchTravel">查询</div>
          <div class="btn-blank w100 btn-height40 mL20" @click="clickClearTravel">重置</div>
        </div>
        <div class="column-me mT20">
          <div class="table-border">
            <el-table :data="travelData" border header-cell-class-name="table-header-style"
                      cell-class-name="table-cell-style">
              <el-table-column type="index" label="序号" width="80" align="center"/>
              <el-table-column prop="id" label="行程ID" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="startTime" label="行程开始时间" align="center" show-overflow-tooltip
                               width="200"></el-table-column>
              <el-table-column prop="endTime" label="行程结束时间" align="center" show-overflow-tooltip
                               width="200"></el-table-column>
              <el-table-column prop="duration" label="行程总时长" align="center" show-overflow-tooltip
                               width="150"></el-table-column>
              <el-table-column prop="updatedTime" label="最后一次更新时间" align="center" width="200"
                               show-overflow-tooltip></el-table-column>
              <el-table-column label="是否异常" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.isException===1">异常</div>
                  <div v-if="scope.row.isException===0">正常</div>
                </template>
              </el-table-column>
              <el-table-column label="综合评分" align="center" show-overflow-tooltip>
                <template #default="scope">
                  {{ Number(scope.row.score) }}
                </template>
              </el-table-column>
              <el-table-column label="数据详情" align="center" show-overflow-tooltip width="100">
                <template #default="scope">
                  <div class="blue-text" @click="clickViewTravel(scope.row)">查看</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mT10">
            <el-pagination @current-change="handleCurrentChange2" :current-page.sync="pageNoTravel"
                           layout="total, prev, pager, next, jumper" :total="totalTravel">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="行程信息详情" v-model="visibleTravelDetail" width="1000px" top="2vh" :destroy-on-close="true">
      <div>
        <div class="row-me row-center flex-end1">
          <div class="column-me row-center" @click="clickTab(0)">
            <div class="tab-div" :class="{'tab-div-active':tabIndex===0}">图表</div>
          </div>
          <div class="column-me row-center margin-left30" @click="clickTab(1)">
            <div class="tab-div" :class="{'tab-div-active':tabIndex===1}">列表</div>
          </div>
        </div>
        <div v-if="tabIndex===0">
          <div class="column-me">
            <div class="row-me center-all">
              <div class="font-bold">行程心率波动</div>
            </div>
            <div class="h250 mT20">
              <lineChart ref="lineChart1"></lineChart>
            </div>
          </div>
          <div class="column-me mT50">
            <div class="row-me center-all">
              <div class="font-bold">行程血压波动</div>
            </div>
            <div class="h250 mT20">
              <lineChart ref="lineChart2"></lineChart>
            </div>
          </div>
          <div class="column-me mT50">
            <div class="row-me center-all">
              <div class="font-bold">行程血氧波动</div>
            </div>
            <div class="h250 mT20">
              <lineChart ref="lineChart3"></lineChart>
            </div>
          </div>
        </div>
        <div class="mT20" v-if="tabIndex===1">
          <el-table :data="travelDetailData" border header-cell-class-name="table-header-style"
                    cell-class-name="table-cell-style">
            <el-table-column type="index" label="序号" width="80" align="center"/>
            <el-table-column prop="createdTime" label="数据更新时间" align="center" show-overflow-tooltip
                             width="200"></el-table-column>
            <el-table-column prop="heartRate" label="心率" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bloodPressure" label="血压" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bloodOxygen" label="血氧" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="是否异常" align="center" show-overflow-tooltip>
              <template #default="scope">
                <div v-if="scope.row.isException===1">异常</div>
                <div v-if="scope.row.isException===0">正常</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>


    <el-dialog :title="pdfTitle" v-model="visiblePdf" width="1300px" top="0" :destroy-on-close="true"
               :close-on-click-modal="false">
      <iframe style="width:100%;height:90vh;display:block;border:0;" :src="pdfUrl"></iframe>
    </el-dialog>

  </div>
</template>

<script>
import importExcel from "@/components/import/importExcel.vue";
import lineChart from '@/components/homeCharts/lineChart.vue'

export default {
  components: {
    importExcel,
    lineChart
  },
  data() {
    return {
      name: '',
      code: '',
      date: [],
      tableData: [],
      pageNo: 1,
      total: 0,
      multipleSelection: [],
      visibleImport: false,
      visibleUser: false,
      pageNo1: 1,
      total1: 0,
      visibleTravel: false,
      selectDate: [],
      travelData: [],
      pageNoTravel: 1,
      totalTravel: 0,
      visibleTravelDetail: false,
      tabIndex: 0,
      travelDetailData: [],
      detailItem: null,
      pdfTitle: '',
      visiblePdf: false,
      pdfUrl: '',
      downloadUrl: '',
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    importSuccess(e) {
      this.visibleImport = false
      this.$message.success('导入成功')
      if (e === 'refresh') {
        this.clickSearch()
      }
    },
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.code = ''
      this.date = []
      this.clickSearch()
    },
    //用户详情
    clickDetail(item) {
      this.detailItem = this.$method.deepClone(item)
      this.visibleUser = true
      this.getUserDetail()
    },
    //冻结解冻
    clickFreeze(item, status) {
      this.$postForm('/users/userUpdate', {
        id: item.id,
        status: status,
      }).then((res) => {
        if (res) {
          this.$message.success('操作成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickBatchImport() {
      this.$post('/users/healthConfig', {}).then((res) => {
        if (res) {
          this.downloadUrl = res.data.configValue
        }
      }).catch((error) => {
        console.log(error)
      })
      this.visibleImport = true
    },
    clickBatchExport() {
      let ids = this.multipleSelection.map(item => {
        return item.id
      })
      this.$postForm('/users/userDerive', {
        ids: ids
      }).then((res) => {
        if (res) {
          window.open(res.data)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickBatchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请先选择用户")
        return
      }
      this.$confirm("您确定要删除吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.multipleSelection.map(item => {
          return item.id
        })
        this.$postForm('/users/userAllDeleted', {
          ids: ids,
        }).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    handleCurrentChange1(pageNo) {
      this.pageNo1 = pageNo
    },
    handleCurrentChange2(pageNo) {
      this.pageNoTravel = pageNo
      this.getDataTravel()
    },
    handleSelectionChange(e) {
      this.multipleSelection = e
    },
    //预览报告
    clickPreview(item) {
      const reqUrl = item.path
      const x = new window.XMLHttpRequest()
      x.open('GET', reqUrl, true)
      x.responseType = 'blob' //返回格式为blob
      x.onload = () => {
        this.pdfUrl = window.URL.createObjectURL(x.response) //将后端返回的blob文件读取出url
      }
      x.send()
      this.pdfTitle = item.name
      this.visiblePdf = true
    },
    //下载报告
    clickDownload(item) {
      const xhr = new window.XMLHttpRequest();
      xhr.open('GET', item.path, true);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = () => {
        if (xhr.status === 200) {
          const url = window.URL.createObjectURL(xhr.response);
          const a = document.createElement('a');
          a.href = url;
          a.download = item.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      }
    },
    //删除报告
    clickDelete(item) {
      this.$postForm('/users/physicalDeleted', {
        ids: [item.id],
      }).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getUserDetail()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //上传图片验证
    beforeAvatarUpload(file) {
      let types = ['application/pdf'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('只能上传pdf格式文件')
        return false
      }
      return true
    },
    //上传
    uploadFile(param, item) {
      this.$uploadFileAli(param.file, res => {
        let name = res.substring(res.lastIndexOf('/') + 15, res.length)
        this.$postForm('/users/uploadPhysical', {
          userId: item.id,
          name: name,
          img: res,
        }).then((res) => {
          if (res) {
            this.$message.success('上传成功')
          }
        }).catch((error) => {
          console.log(error)
        })
      }, err => {
        console.log(err)
      })
    },
    clickTravel(item) {
      this.detailItem = this.$method.deepClone(item)
      this.getDataTravel()
      this.visibleTravel = true
    },
    getDataTravel() {
      this.$postForm('/users/journeyList', {
        userId: this.detailItem.id,
        startTime: this.selectDate.length === 2 ? this.selectDate[0] : '',
        endTime: this.selectDate.length === 2 ? this.selectDate[1] : '',
        pageNumber: this.pageNoTravel,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.totalTravel = Number(res.data.total)
          this.travelData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickSearchTravel() {
      this.pageNoTravel = 1
      this.getDataTravel()
    },
    clickClearTravel() {
      this.selectDate = []
      this.clickSearchTravel()
    },
    //查看行程
    clickViewTravel(item) {
      this.selectTravel = this.$method.deepClone(item)
      this.tabIndex = 0
      this.travelDetailData = []
      this.visibleTravelDetail = true
      this.getTravelCharts()
    },
    //行程图表
    getTravelCharts() {
      this.$postForm('/users/journeyShow', {
        id: this.selectTravel.id
      }).then((res) => {
        if (res) {
          //-----心率-----
          let xData1 = []
          let yData1 = []
          res.data.heartRateStatData = res.data.heartRateStatData || []
          xData1 = res.data.heartRateStatData.map(item => {
            return item.date
          })
          yData1 = res.data.heartRateStatData.map(item => {
            return item.value
          })
          this.$nextTick(() => {
            this.$refs.lineChart1.initChart(xData1, yData1, '行程心率波动', '#4C7BF8')
          })
          //-----血压-----
          let xData2 = []
          let yData2 = []
          res.data.bloodPressureStatData = res.data.bloodPressureStatData || []
          xData2 = res.data.bloodPressureStatData.map(item => {
            return item.date
          })
          yData2 = res.data.bloodPressureStatData.map(item => {
            return item.value
          })
          this.$nextTick(() => {
            this.$refs.lineChart2.initChart(xData2, yData2, '行程血压波动', '#27e08a')
          })
          //-----血氧-----
          let xData3 = []
          let yData3 = []
          res.data.bloodOxygenStatData = res.data.bloodOxygenStatData || []
          xData3 = res.data.bloodOxygenStatData.map(item => {
            return item.date
          })
          yData3 = res.data.bloodOxygenStatData.map(item => {
            return item.value
          })
          this.$nextTick(() => {
            this.$refs.lineChart3.initChart(xData3, yData3, '行程血氧波动', '#f19323')
          })
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickTab(index) {
      this.tabIndex = index
      if (this.tabIndex === 0) {
        this.getTravelCharts()
      } else if (this.tabIndex === 1) {
        this.getTravelDetail()
      }
    },
    getTravelDetail() {
      this.$postForm('/users/journeyShowList', {
        id: this.selectTravel.id
      }).then((res) => {
        if (res) {
          this.travelDetailData = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$postForm('/users/userPage', {
        name: this.name,
        codeName: this.code,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取用户详情
    getUserDetail() {
      this.$postForm('/users/userShow', {
        id: this.detailItem.id,
      }).then((res) => {
        if (res) {
          this.detailItem = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.userManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &:deep(.el-upload) {
    border: none;
  }

  .tab-div {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #000000;
    border: 1px solid #D6DAE2;
    user-select: none;
    cursor: pointer;

    &-active {
      border-color: #4C7BF8;
      background: #4C7BF8;
      color: #FFFFFF;
    }
  }
}
</style>
